.Confirmation-modal-style {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 45%;
    max-height: 80%;
    padding-top: 2.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 1rem;
    border-radius: 5rem;
}
.Confirmation-modal-body {
    --bs-modal-header-border-width: 0;
    --bs-modal-footer-border-width: 0;
}
.Confirmation-modal-title {
    font-size: 25px;
    font-weight: bold;
    align-self: center;
    text-wrap:wrap;
    padding-left: 5rem;
    padding-right: 5rem;
}
.Confirmation-modal-header {
    display: block;
    --bs-modal-header-border-width: 0;
    --bs-modal-footer-border-width: 0;
}
.Confirmation-modal-footer {
    display: block;
    --bs-modal-header-border-width: 0;
    --bs-modal-footer-border-width: 0;
}
.Confirmation-modal-close {
    position: absolute;
    z-index: 9999;
    top: 0.5rem;
    right: 0;
    cursor: pointer;
    color: grey;
    background-color: white;
    font-size: 20px;
    width: 20px
}
.Confirmation-modal-secondary-button {
    margin-top: 2.5rem;
    float: left;
    width: 45%;
    margin-left: 0;
    margin-right: 0;
}
.Confirmation-modal-primary-button {
    margin-top: 2.5rem;
    float: right;
    width: 45%;
    margin-left: 0;
    margin-right: 0;
}