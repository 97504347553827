.centered-text {
    text-align: center;
}

.steps {
    display: flex;
    justify-content: space-evenly;
    vertical-align: top;
}

.wider-flex {
    flex-grow: 3;
    margin-left: 5em;
}

.horizontal-box {
    display: flex;
    justify-content: left;
    vertical-align: top;
}

.center-align {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.closer-spacing {
    margin-bottom: 0;
}

.smaller-margin {
    margin: 0.5em 0;
    border-top: 1px solid #7d7676;
}

.top-button-spacing {
    margin-bottom: 0.4em;
}

.no-bullet ul li {
    list-style-type: none;
}

.next-step {
    flex-basis: 35%;
}

.button-min-width {
    min-width: 14em;
}