.bg-Banking {
    background-color: #3D64AC;
}

.info-Banking {
    width: 20%;
}

.input-Banking {
    width: 100%;
    position: relative;
}

.border-Banking {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(117, 126, 133);
    width: 95%;
    margin: auto
}

.button-Banking {
    text-align: center;
    right: 25px;
}

.panel-Banking {
    width: 50%;
    display: inline-grid;
}

.banking-Image {
    position: absolute;
    margin-top: 50px;
}

.input-details {
    width: 80%;
}

label {
    color: #000000;
    font-weight: bold;
    padding-bottom: 10px;
}

.header-Banking {
    padding-bottom: 15px;
}