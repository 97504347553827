#radio-cards-container {
    margin-top: 5rem;
    width: 75vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.card-width{
    width: 70% !important;
}

.card-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2; 
    line-clamp: 2; 
    display: -webkit-box;
   -webkit-box-orient: vertical; 
   width: 100%;
}

.radio-card {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 300px;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 3rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.radio-card:hover {
    border: 2px solid #BCBCBC;
    cursor: pointer;
}

.radio-card-check {
    display: none;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
}

.text-center {
    text-align: center;
}

.radio-card-icon img {
    width: 80px;
}

.radio-card-label {
    margin-top: 1rem;
    font-weight: 600;
    font-size: 1.2rem;
}

.radio-card-label-description {
    margin-top: 0.5rem;
    color: rgba(0, 0, 0, 0.7);
}

.radio-card.selected {
    border: 0.1rem solid #0066FF;
    background-color: #F0F4FB;
}

.radio-card.selected .radio-card-check {
    display: inline-flex;
}

.radio-card-disabled {
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 300px;
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 3rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #EDEDED;
}