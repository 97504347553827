.game-segment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25em;
  margin-bottom: 8rem;
  margin-top: 2rem;
}

.wheel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-top: 2rem;
}

.wheel-base {
  position: absolute;
  width: fit-content;
  background-color: white;
  border-radius: 90%;
  border: 2px solid hsla(0, 0%, 53%, 0.451);
  filter: drop-shadow(0px 0px 0px #56565669);
}

.prize-wheel-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.4em solid #ffffff;
  width: 8em;
  height: 8em;
  border-radius: 90px;
  background-color: #ffffff;
}

.inner-circle-small {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5em solid #ffffff;
  width: 7em;
  height: 7em;
  border-radius: 90px;
  background-color: #ffffff;
  box-shadow: 0px 0px 42.9px #071427;
}

.inner-circle-small:hover {
  background-color: #ffffff;
  border: 0.5em solid #1d4a96;
  transform: scale(0.97);
}

.button-text {
  color: #000000;
  text-align: center;
  margin: 5px;
  transform: scale(0.8);
}

.button-text-top {
  display: grid;
  font-size: 1.25em;
  font-weight: bolder;
  line-height: 1.3em;
  text-align: center;
  width: 100%;
}

.button-text-bottom {
  display: grid;
  font-size: 1.85em;
  font-weight: bolder;
  line-height: 0.8em;
  margin: 0em;
  text-align: center;
  width: 100%;
}

div[disabled] {
  pointer-events: none;
  opacity: 0.7;
}

.mute-button {
  text-align: center;
}

.spin-text {
  position: relative;
  top: 1rem;
  left: 1rem;
  display: inline-block;
}

.disclaimer {
  margin-top: 2rem;
}

.spin-text-with-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}
