.upcoming-campaign-cards {
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 2rem;
  border-radius: 1.6rem;
  width: 100%;
  height: 25rem;
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
}

.upcoming-campaign-cards-figma {
  background-color: #fff;
  border: 1px solid #ededed;
  padding: 2rem;
  border-radius: 1.6rem;
  width: 100%;
  flex-wrap: wrap;
  margin: 0px;
}

.counter-text {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  margin-top: .5rem;
}

.upcoming-campaign-cards-head {
  margin: 0px;
}
