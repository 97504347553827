.modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 35rem;
  height: 37rem;
  align-items: center;
  padding-bottom: 1rem;
  padding-top: 5rem;
  border-radius: 16px;
}

@keyframes popper {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes confetti {
  0% {
    transform: scale(0);
    opacity: 75%;
  }
  50% {
    transform: scale(1.2);
    opacity: 100%;
  }
  100% {
    transform: scale(1);
    opacity: 0%;
  }
}

.stacked-image-confetti {
  top: 0;
  left: 2rem;
  opacity: 0%;
  animation-name: confetti;
  animation-duration: 1.5s;
  z-index: 2;
}

.stacked-image-circle {
  position: absolute;
  top: 3rem;
  left: 8.25rem;
  animation-name: popper;
  animation-duration: 1s;
  z-index: 1;
}

.text-body {
  text-align: center;
  position: relative;
}

.continue-button {
  width: 10rem;
  margin-right: 0px;
}