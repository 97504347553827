#pageHeader {
  margin-left: -500px;
  width: 2000px;
  height: 85px;
  margin-bottom: 30px;
  margin-top: -48px;
  text-align: center;
}
#taxFormContainer{
  width: 60em;
  align-content: center;
  padding: 2em;
}
.privacyText{
  font-style: italic;
}
.bottom {
  margin-bottom: 20px;
  padding: 0;
}
.pheader {
  height: 75px;
}
.formDisplay {
  width: 60%;
  display: inline-block;
}
label {
  color: #000000;
  font-weight: bold;
  padding-bottom: 10px;
}
.error-validation-text {
  color: #E00B20;
}

