.custom-modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 85%;
  align-items: center;
  padding-bottom: 12rem;
  padding-top: 12rem;
  border-radius: 10rem;
}

.modal-title {
  font-size: 100px;
  font-weight: bold;
  color: #1d4a96;
  text-align: center;
}

.modal-body-game {
  text-align: center;
}

.modal-close {
  position: absolute;
  z-index: 9999;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  color: #22841f;
  background-color: white;
  font-size: 30px;
}
