.ActivePromotion-modal-style {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    max-height: 80%;
    padding-top: 2.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 1rem;
    border-radius: 5rem;
  }
  .ActivePromotion-modal-title {
    font-size: 30px;
    font-weight: bold;
    align-self: center;
    text-wrap:wrap;
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .ActivePromotion-modal-header {
    display: block
  }
  .ActivePromotion-modal-subtitle {
    font-size: 1.6rem;
    line-height: 2.4rem;
    text-align: center;
    padding-bottom: 1rem;
    color:black;
  }
  
  .ActivePromotion-modal-body {
    text-align: left;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .ActivePromotion-modal-close {
    position: absolute;
    z-index: 9999;
    top: 4.5rem;
    right: 0;
    cursor: pointer;
    color: black;
    background-color: white;
    font-size: 20px;
  }
  .ActivePromotion-modal-button {
    margin-top: 2.5rem;
    float: right;
  }
  .ActivePromotion-modal-footer-text {
    font-weight: bold;
    text-align: left;
  }