.main-padding {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.footer {
    padding-top:5rem;
}

li.menu {
    list-style-type: none;
}