.header-main {
  text-align: center;
}
.Earnings-container { 
  padding-left: 20rem;
  padding-right: 20rem;
}
.Earnings-footer {
  text-align: center;
  display: block;
  width: 99%;
  margin-bottom: 0;
  padding: 10px;
}
