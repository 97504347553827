.UpcomingPromotion-modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  height: 60%;
  padding: 3rem;
  border-radius: 5rem;
}
  
.UpcomingPromotion-modal-title {
  font-size: 30px;
  font-weight: bold;
  align-self: center;
}
  
.UpcomingPromotion-modal-body {
  text-align: left;
}
  
.UpcomingPromotion-modal-close {
  position: absolute;
  z-index: 9999;
  top: 3rem;
  right: 0;
  cursor: pointer;
  color: black;
  background-color: white;
  font-size: 25px;
}

.UpcomingPromotion-modal-button {
  position:absolute;
  align-self: flex-end;
}