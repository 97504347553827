.universal-modal-close {
  position: fixed;
  z-index: 9999;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
  color: #616161;
  background-color: white;
  font-size: 2.4rem;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.universal-modal-style {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80rem;
  height: 50rem;
  padding-top: 3rem;
  border-radius: 16px;
}

.universal-modal-body {
  padding-left: 2rem;
  padding-right: 2rem;
}

.universal-modal-header {
  padding-left: 2rem;
  padding-right: 2rem;
}
