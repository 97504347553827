.progress-segment {
  height: 45px;
  padding: 2px;
}
.left {
  border-top-left-radius: 120px;
  border-bottom-left-radius: 120px;
}
.right {
  border-top-right-radius: 120px;
  border-bottom-right-radius: 120px;
}
.progress-fill {
  background-color: #004b9b;
  width: 100%;
  height: 100%;
}
.progress-empty {
  background-color: #dcdcdc;
  width: 100%;
  height: 100%;
}
.progress-width {
  width: 100%;
}
.progress-text-div {
  position: relative;
  top: .75rem;
  left: 1rem;
  margin: 0;
}
.progress-text-lines {
  margin: 0;
}
.flex-box-img-text {
  display: flex; 
  align-items: center;
  padding-bottom: 4rem;
  position: relative;
}
.spif-active-area {
  padding-top: 5rem;
}
