.flex-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.header-main {
  text-align: center;
}

.wheel-padding {
  padding: 4rem;
}

.btn-text {
  text-transform: none;
}

#game-return-home{
  margin: 0px;
}