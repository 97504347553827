.header-main {
  text-align: center;
}

.figma-header {
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.header-container {
  border-radius: 1.6rem;
  padding: 2rem;
  padding-bottom: 0rem;
}

.main {
  padding-left: 20rem;
  padding-right: 20rem;
}

.principal-box {
  background-color: #f0f4fb;
  width: 70%;
}

.principal-filter {
  padding-top: 4rem;
  padding-left: 0rem;
  text-align: left;
}

.principal-filter-figma {
  padding-left: 0rem;
  text-align: left;
}

.money-green {
  color: #22841f;
}

.progress-bar {
  padding-top: 12rem;
}

.progress-text {
  text-align: right;
}

.flexible-container {
  text-align: center;
  border: 1px solid #CAC9C9;
  border-radius: 16px;
  padding-top: 2rem;
  margin-bottom: 2.5rem;
}

.total-earned-container {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-bottom: 5rem;
  padding-left: 2rem;
}

.total-earned-button {
  padding-top: 1rem;
}

.days-left-counter {
  color: #616161;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.days-left-counter-bold {
  color: #000000;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  font-weight: bold;
}

.description-text {
  margin-bottom: 1rem;
}

.redemption-days-left-counter {
  color: red;
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.floated-r {
  float: right;
}

.game-section {
  padding-bottom: 1rem;
}

.game-header {
  margin-bottom: 1rem;
}

.home-page-game-header {
  display: inline;
}

.home-page-game-header-icon {
  margin-left: 0.5rem;
  vertical-align: middle !important;
}

.game-indicator {
  text-align: center;
  padding: 4rem;
}

.gift-image:hover {
  transform: scale(1.05) rotate(2deg);
}

.game-number-spins-text {
  text-align: center;
}

.title-padding {
  padding-bottom: 2rem;
}

.earnings-button {
  margin-left: 1.5rem;
}

.notice-bar {
  --bs-alert-padding-x: 0;
  --bs-alert-padding-y: 0;
}

.notice-header {
  margin-top: 1.2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.gecko-padding {
  padding-top: 127px;
}
